import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication } from '@wix/members-area-app-definitions';

import { shouldHideProfileCardForNonSocialSites } from '../../../../utils/experiments';
import { showProfileCard } from './members-area-page';

export const maybeShowProfileCard = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  const isProfileCardToggleEnabled = await shouldHideProfileCardForNonSocialSites();
  if (!isProfileCardToggleEnabled) {
    return;
  }

  const hasSocialPage = definitions.some(({ social }) => social);
  if (hasSocialPage) {
    return showProfileCard(editorSDK);
  }
};
